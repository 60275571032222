import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  user: undefined,
  users: [],
  perPage: undefined,
  totalRows: undefined,
  selectedUser: undefined,
  needOpenModalForNewUser: false,
  userAuth: undefined,
  userPermissions: []
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

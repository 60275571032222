import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  workAreas: [],
  perPage: undefined,
  totalRows: undefined,
  selectedWorkArea: undefined,
  needOpenModalForNewWorkArea: false
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}

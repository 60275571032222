import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getShownColumnsKeys } from '@/Utils/helpers'
const getColumns = () => {
  const initColumns = [
    { label: 'Название', key: 'name', class: 'text-left', tdClass: 'table-td-name', fixed: true },
    { label: 'Склад', key: 'location', class: 'text-center', tdClass: 'table-td-storage' },
    { label: 'Ответственный', key: 'responsible', class: 'text-left', tdClass: 'table-td-responsible' },
    { label: 'Модуль', key: 'module', class: 'text-center', tdClass: 'table-td-module' },
    { label: 'Выдача', key: 'arrival_at', class: 'text-center', tdClass: 'table-td-arrival' },
    { label: 'Приход', key: 'supply_at', class: 'text-center', tdClass: 'table-td-supply' },
    { label: 'Статус', key: 'status', class: 'text-center', tdClass: 'table-td-status' }
  ]
  const initColumnsKeys = getShownColumnsKeys('instrument')
  return initColumns.map(item => {
    if (item.fixed) return item
    const show = !initColumnsKeys || initColumnsKeys.includes(item.key)
    return { ...item, show }
  })
}

const getColumnsForInspector = () => {
  const initColumns = [
    { label: 'Номер', key: 'idx', class: 'text-center', tdClass: 'table-td-id' },
    { label: 'Инструмент', key: 'name', class: 'text-center', tdClass: 'table-td-name' },
    { label: 'Ответственный', key: 'users', class: 'text-center', tdClass: 'table-td-responsible' },
    { label: 'Зона работ', key: 'work_zones', class: 'table-td-zone text-center', tdClass: 'table-td-zone' },
    { label: 'В зоне', key: 'in_zone', class: 'text-center', tdClass: 'table-td-zone' },
    { label: 'Автоматически', key: 'automatically', class: 'text-center', tdClass: 'table-td-zone' },
    { label: 'Инспектор', key: 'human', class: 'text-center', tdClass: 'table-td-zone' },
    { label: 'Время контроля', key: 'updated_at', class: 'text-center', tdClass: 'table-td-created_at' }
  ]
  const initColumnsKeys = getShownColumnsKeys('instrumentForInspector')
  return initColumns.map(item => {
    if (item.fixed) return item
    const show = !initColumnsKeys || initColumnsKeys.includes(item.key)
    return { ...item, show }
  })
}

const getColumnsForForeman = () => {
  const initColumns = [
    { label: 'Номер', key: 'idx', class: 'text-center', thStyle: { width: '3%' } },
    { label: 'Название', key: 'name', class: 'text-center', tdClass: 'table-td-name', thStyle: { width: '16%' } },
    { label: 'Статус', key: 'statuses', class: 'text-center', tdClass: 'table-td-status', thStyle: { width: '7%' } },
    // { label: 'Утиль', key: 'junk', class: 'text-center', tdClass: 'table-td-junk', thStyle: { width: '6%' } },
    { label: 'Потеряно', key: 'loss', class: 'text-center', tdClass: 'table-td-junk', thStyle: { width: '6%' } },
    { label: 'Заявка', key: 'claim', class: 'text-center', tdClass: 'table-td-claim', thStyle: { width: '6%' } },
    { label: 'Картинка', key: 'images', class: 'text-center', tdClass: 'table-td-junk', thStyle: { width: '6%' } },
    { label: 'Ответственный', key: 'users', class: 'text-center', tdClass: 'table-td-responsible', thStyle: { width: '15%' } }
  ]
  const initColumnsKeys = getShownColumnsKeys('instrumentForForeman')
  return initColumns.map(item => {
    if (item.fixed) return item
    const show = !initColumnsKeys || initColumnsKeys.includes(item.key)
    return { ...item, show }
  })
}

const state = {
  columns: getColumns(),
  columnsForInspector: getColumnsForInspector(),
  columnsForForeman: getColumnsForForeman(),
  instrument: [],
  instruments: [],
  instrumentShow: undefined,
  selectedInstruments: [],
  scannedRfidMark: null,
  instrumentsWithoutRfid: [],
  instrumentWithoutRfid: null,
  needReloadInstruments: false,
  perPage: undefined,
  totalRows: undefined,
  scannedInstruments: [],
  assignModuleMode: '',
  selectedInstrumentType: undefined,
  selectedInstrument: undefined,
  needOpenModalForNewInstrument: false,
  addedInstrument: {},
  usersWithPasses: [],
  search: '',
  userJunk: ''
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}

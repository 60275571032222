import { INSTRUMENT_STATUSES } from '@/Utils/constants'

export default {
  configs: state => state.configs,
  instruments: state => state.configs ? state.configs.instruments : undefined,
  instrumentsWorkZones: state => state.configs ? state.configs.work_zones : undefined,
  instrumentStatuses: state => {
    if (!state.configs || !state.configs.statuses) return
    return state.configs.statuses.filter(item => INSTRUMENT_STATUSES.includes(item.name))
  },
  taskStatuses: state => state.configs.task_statuses,
  filteredInstruments: state => {
    if (!state.instrumentNameFilter) return state.configs ? state.configs.instruments : null
    const nameFilter = state.instrumentNameFilter.toLowerCase()
    return state.configs.instruments.filter(item => item.name.toLowerCase().includes(nameFilter))
  },
  responsible: state => state.configs ? state.configs.responsible : [],
  filteredResponsibles: state => {
    if (!state.responsibleFilter) return state.configs ? state.sortedResponsiples : null
    const nameFilter = state.responsibleFilter.toLowerCase()

    return state.configs.responsible.filter(item => {
      const fullName = `${item.first_name} ${item.last_name} ${item?.patronymic || ''}`
      return fullName.toLowerCase().includes(nameFilter)
    })
  },
  filteredWorkZones: state => {
    if (!state.workZoneFilter) return state.configs ? state.configs.work_zones : null
    const nameFilter = state.workZoneFilter.toLowerCase()
    return state.configs.work_zones.filter(item => {
      return item.title ? item.title.toLowerCase().includes(nameFilter)
        : item.description.toLowerCase().includes(nameFilter)
    })
  },
  roles: state => state.configs.roles,
  positions: state => state.configs.positions,
  font: state => state.font,
  positionResponsible: state => state.configs.position_responsible,
  comments: state => state.configs.comment_types
}

export default {
  setEquipments (state, value) {
    state.equipments = value
  },
  setPerPage (state, value) {
    state.perPage = value
  },
  setTotalRows (state, value) {
    state.totalRows = value
  },
  setNeedOpenModalEquipment (state, value) {
    state.needOpenModalEquipment = value
  },
  setSelectedEquipment (state, value) {
    state.selectedEquipment = value
  },
  setEquipmentShow (state, data) {
    state.instrumentShow = data
  }
}

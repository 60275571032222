import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  positions: [],
  perPage: undefined,
  totalRows: undefined,
  selectedPosition: undefined,
  needOpenModalForPosition: false
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}

import axios from '@/store/axios'

export default {
  async downloadStorekeeperConfigs ({ commit }) {
    try {
      const configs = await axios.get('storekeeper/configs')
      commit('setConfigs', configs)
    } catch (e) {
      if (!e.logout) {
        console.log('Ошибка при получении конфигурации в методе downloadStorekeeperConfigs')
      }
    }
  },
  async downloadConfigs ({ commit }) {
    try {
      const configs = await axios.get('/configs')
      commit('setConfigs', configs)
    } catch (e) {
      if (!e.logout) {
        console.log('Ошибка при получении конфигурации')
      }
    }
  },
  installNormalFont ({ commit }) {
    commit('setFont', 'normal')
  },
  installLargeFont ({ commit }) {
    commit('setFont', 'large')
  }
}

import moment from 'moment'

export default {
  setFullScreenGantt: (state, value) => {
    console.log('setFullScreenGantt', value)
    state.fullScreenGantt = value
  },
  setImportProjectId: (state, value) => {
    state.importProjectId = value
  },
  setProjects: (state, projects) => {
    state.projects = projects
  },
  setProject: (state, project) => {
    state.project = project
  },
  setUpdatedProject: (state, value) => {
    state.updatedProject = {
      text: value.name,
      start_date: value.start_date,
      end_date: value.end_date,
      id: value.id,
      image: value.image
    }
  },
  updateListProjects: (state, payload) => {
    if (state.projects && state.projects.length) {
      const project = state.projects.find((project) => project.id === payload.id)
      if (project) {
        project.name = payload.text
        project.start_date = moment(payload.start_date).format('YYYY-MM-DD HH:mm:ss')
        project.end_date = moment(payload.end_date).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  },
  setSelectedProject: (state, value) => {
    state.selectedProject = value
  },
  setSearchTask: (state, value) => {
    state.searchTask = value
  },
  setProjectSearch: (state, value) => {
    state.projectSearch = value
  },
  setPatterns: (state, patterns) => {
    state.patterns = patterns
  },
  setPerPage (state, value) {
    state.perPage = value
  },
  setTotalRows (state, value) {
    state.totalRows = value
  },
  setErrors (state, value) {
    state.errors = value
  },
  updateLocalStorageData (state, data) {
    if (!data) return
    state.projectGanttLS = data
    localStorage.setItem('projectGantt', JSON.stringify(data))
  }
}

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  pageTitle: undefined,
  pageIcon: undefined,
  isOpenSidebar: JSON.parse(localStorage.getItem('sidebarMini')) ?? true
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}

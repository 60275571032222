import axios from '../axios'
export default {
  async FETCH_NOTIFICATIONS ({ state, commit }, payload) {
    const params = payload ? payload.page : 1
    try {
      const response = await axios.get(`/notifications?page=${params}`)
      commit('SET_NOTIFICATIONS', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)

      if (payload && payload.getNoRead) {
        const noRead = await axios.get('/notifications?read=0')
        commit('setTotalNoRead', noRead.total)
      } else {
        commit('setTotalNoRead', state.totalNoRead + 1)
      }
    } catch (e) {
      console.log('e', e)
    }
  },
  async readNotification ({ state, commit }, id) {
    try {
      const res = await axios.post(`/notifications/${id}/read`)
      commit('setReadNotification', res)
      commit('setTotalNoRead', state.totalNoRead - 1)
    } catch (e) {
      console.log('e', e)
    }
  }
}

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const localHistoryFilters = localStorage.getItem('historyFilters')
const localHistorySearch = localStorage.getItem('historySearch')
const localHistoryUser = localStorage.getItem('historyUser')

export const initHistoryFilters = {
  dateFrom: undefined,
  dateTo: undefined,
  responsible: undefined
}

const state = {
  filters: localHistoryFilters ? JSON.parse(localHistoryFilters) : initHistoryFilters,
  user: localHistoryUser ? JSON.parse(localHistoryUser) : {},
  search: localHistorySearch || '',
  statusHistory: [],
  selectedProduct: undefined
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default {
  setFullScreenVideoWall: (state, value) => {
    state.fullScreenVideoWall = value
  },
  setVideoSources (state, value) {
    state.videoSources = value
  },
  setDomedVideoSources (state, value) {
    state.domedVideoSources = value
  },
  setPerPage (state, value) {
    state.perPage = value
  },
  setTotalPage (state, value) {
    state.totalPage = value
  },
  setTotalRows (state, value) {
    state.totalRows = value
  },
  setDomedTotalRows (state, value) {
    state.domedTotalRows = value
  },
  setNeedOpenModalForNewVideoSource (state, value) {
    state.needOpenModalForNewVideoSource = value
  },
  setSelectedVideoSource (state, value) {
    state.selectedVideoSource = value
  },
  setFormIsValid (state, value) {
    state.formIsValid = value
  }
}

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  project: {},
  projects: [],
  selectedProject: undefined,
  patterns: [],
  searchTask: '',
  perPage: undefined,
  totalRows: undefined,
  updatedProject: undefined,
  projectGanttLS: JSON.parse(localStorage.getItem('projectGantt')),
  fullScreenGantt: false,
  projectSearch: '',
  importProjectId: ''
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import axios from 'axios'
import store from './index'
import router from '../router'
import { getErrorMessage } from '@/Utils/helpers'

const authHeader = localStorage.getItem('token')
  ? { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  : {}

const $axios = axios.create({
  baseURL: process.env.VUE_APP_BACKEND,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    // 'X-Requested-With': 'XMLHttpRequest',
    ...authHeader
  }
})

$axios.interceptors.request.use(
  (config) => {
    if (config.method === 'put' && config.data && config.data.set) {
      config.data.set('_method', 'PUT')
      config.method = 'post'
    }
    if (config.method === 'delete' && config.data) {
      config.data.set('_method', 'delete')
      config.method = 'post'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

$axios.interceptors.response.use(
  (response) => {
    response = response.data && response.data.data ? response.data.data : response.data
    return response
  },
  error => {
    const isSession = localStorage.getItem('token')
    if (!isSession) {
      error.logout = true
      return Promise.reject(error)
    }

    if (error.__CANCEL__) return Promise.reject(error)

    const status = error.response ? error.response.status : null
    if ([404, 422].includes(status)) {
      const message = getErrorMessage(error.response)
      store.commit('Notify/setError', message)
      error.handled = true
    }
    if (status === 401) {
      store.dispatch('Auth/signOut')
      router.push('/sign-in')
      return Promise.resolve('logout')
    }
    return Promise.reject(error)
  }
)

export default $axios

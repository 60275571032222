import axios from '../axios'

export default {
  // Получение списка проектов
  async downloadProjectsList ({ commit, state }, searchProject) {
    try {
      commit('setProjectLoading', true)

      const response = await axios.get('/projects', {
        params: {
          limit: 1000,
          search: searchProject
        }
      })

      const projects = response.data
      commit('setProjectList', projects)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setProjectLoading', false)
    }
  },
  async deleteProject ({ commit }, id) {
    // Удаление проекта
    try {
      commit('setDeleteLoading', true)

      await axios.delete(`projects/${id}`)

      commit('setDeleteProject', id)
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setDeleteLoading', false)
    }
  },
  async cloneProject ({ state, commit }, project) {
    // Клонировать проект
    try {
      commit('setCloneLoading', true)

      const response = await axios.post(`projects/${project.id}/clone`, project)
      commit('setNewProject', response)

      commit('Notify/setSuccess', 'Проект успешно склонирован', { root: true })
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При клонировании произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setCloneLoading', false)
    }
  },
  async archiveProject ({ commit }, id) {
    // Архивировать проект
    const data = new FormData()
    data.set('is_active', 1)

    try {
      commit('setArchiveLoading', true)

      const response = await axios.put(`projects/${id}`, data)

      commit('updateProject', response)
    } catch (e) {
      commit('Notify/setError', 'При архивации произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setArchiveLoading', false)
    }
  },
  async unArchiveProject ({ commit }, id) {
    // Разархивировать проект
    const data = new FormData()
    data.set('is_active', 0)

    try {
      commit('setUnArchiveLoading', true)

      const response = await axios.put(`projects/${id}`, data)

      commit('updateProject', response)
    } catch (e) {
      commit('Notify/setError', 'При разархивации произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setUnArchiveLoading', false)
    }
  }
}

import getters from './getters'
import mutations from './mutations'

const state = {
  info: null,
  success: null,
  error: null,
  danger: null
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}

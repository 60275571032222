export default {
  setPageTitle (state, value) {
    state.pageTitle = value
  },
  setPageIcon (state, value) {
    state.pageIcon = value
  },
  clearPageTitle (state) {
    state.pageTitle = undefined
    state.pageIcon = undefined
  },
  toggleSidebar (state, value) {
    if (value) {
      state.isOpenSidebar = !value
    } else {
      state.isOpenSidebar = !state.isOpenSidebar
    }
  }
}

export default {
  IS_WARNING_NOTIFICATIONS: state => state.notifications ? state.notifications.some(notification => notification.data.priority > 1 && !notification['read_at']) : false,
  notifications: state => state.notifications,
  firstClick: state => state.firstClick,
  perPage: state => state.perPage,
  totalRows: state => state.totalRows,
  ganttIsBlock: state => state.ganttIsBlock,
  importIsBlock: state => state.importIsBlock,
  needShowImportTab: state => state.needShowImportTab,
  totalNoRead: state => state.totalNoRead
}

export default {
  setProjectList: (state, data) => {
    if (data) {
      state.projectList = data
    }
  },
  setNewProject: (state, data) => {
    if (data) {
      state.projectList.unshift(data)
    }
  },
  setSelectedProjectId: (state, data) => {
    if (data) {
      state.selectedProjectId = data
    }
  },
  setDeleteProject: (state, data) => {
    if (data) {
      state.projectList = state.projectList.filter(item => item.id !== data)
    }
  },
  updateProject: (state, project) => {
    state.projectList.forEach((item, index) => {
      if (item.id === project.id) {
        state.projectList[index] = project
      }
    })
  },
  setProjectLoading: (state, data) => {
    state.isProjectLoading = data
  },
  setDeleteLoading: (state, data) => {
    state.isProjectDelete = data
  },
  setCloneLoading: (state, data) => {
    state.isProjectClone = data
  },
  setArchiveLoading: (state, data) => {
    state.isProjectArchive = data
  },
  setUnArchiveLoading: (state, data) => {
    state.isProjectUnArchive = data
  }
}

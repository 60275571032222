import axios from '../axios'

export default {
  async downloadListInstruments ({ state, commit }, payload) {
    try {
      let url = '/instruments' + `${payload && payload.args ? payload.args : 'page=1'}`
      if (payload && ~payload.args.indexOf('storages')) url = '/admin' + url
      if (!payload) url = '/instruments'
      const response = await axios.get(url)

      if (payload.pagination) {
        response.data ? state.instruments.push(...response.data) : state.instruments.push(...response)
        commit('setInstruments', state.instruments)
      } else {
        commit('setInstruments', response.data || response)
      }

      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      return response
    } catch (e) {
      console.log(e)
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async instrumentShow ({ rootState, state, commit }, id) {
    try {
      const response = await axios.get(`/instruments/${id}`)
      if (response) {
        commit('setInstrumentShow', response)
      }
      return response
    } catch (e) {
      if (!e.cancelled) {
        console.error(e)
      }
    }
  },
  async downloadListAdminInstruments ({ state, commit }, payload) {
    try {
      let url = '/admin/instruments' + `${payload && payload.args ? payload.args : '?page=1'}`
      if (!payload) url = '/admin/instruments'
      const response = await axios.get(url)

      if (payload.pagination) {
        response.data ? state.instruments.push(...response.data) : state.instruments.push(...response)
        commit('setInstruments', state.instruments)
      } else {
        commit('setInstruments', response.data || response)
      }

      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      return response
    } catch (e) {
      console.log(e)
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadListInstrumentsDocument ({ commit }, payload) {
    try {
      const response = await axios.get('foreman/instrument/list' + `?${payload && payload.args ? payload.args : 'page=1'}`)
      commit('setInstruments', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      return response
    } catch (e) {
      console.log(e)
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadInstrumentsDocumentWithoutList ({ commit }, payload) {
    try {
      const response = await axios.get('foreman/instruments' + `${payload && payload.args ? payload.args : 'page=1'}`)
      commit('setInstruments', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      return response
    } catch (e) {
      console.log(e)
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadListInstrumentsWorkZone ({ commit }, payload) {
    try {
      const response = await axios.get('/inspector/work-zone/instruments' + `${payload && payload.args ? payload.args : '?page=1'}`)
      commit('setInstruments', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      return response
    } catch (e) {
      console.log(e)
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  clearListInstruments ({ commit }) {
    commit('setInstruments', [])
  },
  async downloadInstrumentsWithoutRfid ({ state, commit }, payload) {
    const { search, all } = payload
    try {
      const searchParam = search ? `search=${search}` : ''
      const allParam = all ? 'all=true' : ''
      const params = [searchParam, allParam].filter(item => !!item).join('&')
      const response = await axios.get(`/storekeeper/warehouses?${params}`)
      commit('setInstrumentsWithoutRfid', response.data)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
      throw e
    }
  },
  async downloadUsersWithPasses ({ state, commit }, payload) {
    try {
      const data = await axios.get(`/inspector/users-with-passes?${payload ? payload.args : ''}`)
      commit('setUsersWithPasses', data || [])
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка ответственных произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async uploadNewInstrumentWithRfid ({ state, getters, commit }, instrumentId) {
    try {
      const pay = {
        instrument_id: instrumentId,
        uuids: getters.scannedRfidMark
      }
      await axios.post('/storekeeper/assign/mark', pay)
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      throw (e)
    }
  },
  async confirmJunk ({ state, commit }, instrumentId) {
    try {
      const data = new FormData()
      data.set('id', instrumentId)
      await axios.put(`foreman/disposal/confirm/${instrumentId}`, data)
      commit('Notify/setSuccess', 'Утиль подтверджен', { root: true })
    } catch (e) {
      commit('Notify/setError', 'Не удалось подтвердить утиль. Попробуйте позже.', { root: true })
      throw (e)
    }
  },
  async confirmLoss ({ state, commit }, instrumentId) {
    try {
      const data = new FormData()
      data.set('id', instrumentId)
      await axios.put(`foreman/loss/${instrumentId}`, data)
      commit('Notify/setSuccess', 'Инструмент потерян', { root: true })
    } catch (e) {
      commit('Notify/setError', 'Не удалось подтвердить утиль. Попробуйте позже.', { root: true })
      throw (e)
    }
  },
  async cancelJunk ({ state, rootGetters, commit }, instrumentId) {
    try {
      const user = rootGetters['Auth/user']
      const data = new FormData()
      data.set('id', instrumentId)
      data.set('responsible_id', user.id)
      await axios.put(`foreman/disposal/cancel/${instrumentId}`, data)
      commit('Notify/setSuccess', 'Утиль отменен', { root: true })
    } catch (e) {
      commit('Notify/setError', 'Не удалось отменить утиль. Попробуйте позже.', { root: true })
      throw (e)
    }
  },
  async sendClaim ({ state, commit }, instrumentId) {
    try {
      const data = new FormData()
      data.set('id', instrumentId)
      await axios.put(`foreman/confirm/preorder/${instrumentId}`, data)
      commit('Notify/setSuccess', 'Заявка отправлена', { root: true })
    } catch (e) {
      commit('Notify/setError', 'Не удалось отправить заявку. Попробуйте позже.', { root: true })
      throw (e)
    }
  },
  async uploadAssignedTools ({ rootGetters, state, commit }) {
    try {
      const user = rootGetters['Employee/scannedEmployeePass']
      const data = new FormData()
      data.set('user', user.id)
      state.scannedInstruments.map((item, index) => {
        data.set(`instruments[${index}]`, item.id)
      })
      await axios.post('storekeeper/issued/instruments', data)
      return `${user.last_name} ${user.first_name}`
    } catch (e) {
      commit('Notify/setError', 'Не удалось выдать инструменты. Попробуйте позже.', { root: true })
      throw (e)
    }
  },
  async addNewInstrumentInspector ({ commit, state }) {
    // отдельный запрос на добавление инструмента на странице "Зона проведения работ"
    // вынесен из uploadNewInstrument, можно перенести в отдеьлный модуль в сторе
    const data = new FormData()
    const instrument = state.addedInstrument
    if (instrument.responsible && instrument.responsible.id) {
      data.set('user', instrument.responsible.id)
    }
    data.set('work_zone', instrument.work_zone.name)
    data.set('in_zone', '1')
    if (instrument.items && instrument.items.length) {
      instrument.items.map((item, index) => {
        data.set(`items[${index}]`, item)
      })
    }
    if (instrument.title && instrument.title.length) {
      instrument.title.map((item, index) => {
        data.set(`title[${index}]`, item.title)
      })
    }
    try {
      const response = await axios.post('/inspector/instruments/by-id', data)
      commit('setNeedReloadInstruments', true)
      commit('setAddedInstrument', undefined)
      commit('setSelectedInstrument', {
        ...response
      })
      const name = response.name ? `${response.name} ` : ''
      commit('Notify/setSuccess', `Инструмент ${name}успешно добавлен`, { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadNewInstrument ({ commit, rootGetters, state }, model) {
    // отдельный запрос на добавление инструмента на странице "Инструменты"
    const data = new FormData()
    for (const [key, value] of Object.entries(model)) {
      data.set(key, String(value))
    }

    try {
      const response = await axios.post('/admin/instruments', data)
      commit('setNeedReloadInstruments', true)
      commit('setAddedInstrument', undefined)
      commit('setSelectedInstrument', {
        ...response
      })
      const name = response.name ? `${response.name} ` : ''
      commit('Notify/setSuccess', `Инструмент ${name}успешно добавлен`, { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedInstrument ({ state, commit }) {
    try {
      const id = state.selectedInstrument.id
      await axios.delete(`/admin/instruments/${id}`)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedInstrument ({ state, rootGetters, commit }, payload) {
    try {
      const { id, model } = payload
      const data = new FormData()
      for (const [key, value] of Object.entries(model)) {
        data.set(key, String(value))
      }
      await axios.put(`/admin/instruments/${id}`, data)
      const updatedInstrument = {
        ...state.selectedInstrument,
        ...model
      }
      commit('setSelectedInstrument', updatedInstrument)
      commit('Notify/setSuccess', `Инструмент ${name}успешно изменён`, { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadRemovedInstrumentFromZone ({ state, commit }, payload) {
    const data = new FormData()

    data.set('work_zone', payload.work_zone.name)
    data.set('in_zone', '0')
    data.set('items[0]', payload.warehouse_uuid)
    try {
      const response = await axios.post('/inspector/instruments', data)
      commit('Notify/setSuccess', 'Инструмент удален из зоны работ', { root: true })
      return !!response
    } catch (e) {
      console.info(e)
      commit('Notify/setError', 'При удалении инструмента из зоны работ произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async massRemovalTools ({ commit }, payload) {
    try {
      await axios.post('/inspector/instruments/by-id/delete', payload)
      commit('Notify/setSuccess', 'Инструменты удалены из зоны работ', { root: true })
      return true
    } catch {
      commit('Notify/setError', 'При удалении инструментов из зоны работ возникла ошибка', { root: true })
    }
  },
  async replaceNecessaryInstruments ({ commit }, payload) {
    try {
      await axios.post('/instruments/equivalent/attach', payload)
      commit('Notify/setSuccess', 'Необходимые инструменты заменены', { root: true })
    } catch {
      commit('Notify/setError', 'При замене необходимых инструментов возникла ошибка', { root: true })
    }
  },
  async handoverTools ({ commit }, data) {
    console.log('da', data)
    try {
      const res = await axios.post('/integration/instruments/in_module', data)
      commit('Notify/setSuccess', 'Инструмент успешно сдан', { root: true })
      return res
    } catch {
      commit('Notify/setError', 'При сдаче инструмента возникла ошибка', { root: true })
    }
  }
}

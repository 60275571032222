import { createApp } from 'vue'
import 'mutationobserver-shim'
import './Utils/filter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import VueAxios from 'vue-axios'
import $axios from './store/axios'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import moment from 'moment'
import { Vue3Mq } from 'vue3-mq'
import mqConfig from './mq/mq'
import toast from './plugins/toast'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import vClickOutside from './directives/v-click-outside'
import JsonExcel from 'vue-json-excel3'
import _ from 'lodash'
import VueFullscreen from 'vue-fullscreen'
import SocketIO from 'socket.io-client'
import VueTippy from 'vue-tippy'
import contextPlugin from '@/Utils/context-plugin'
import registerGlobalComponents from '@/iconsGlobalRegestration'
import '@g/src/assets/styles/general.css'
import '../src/assets/css/common/common-classes.css'

moment.locale('ru')

export const app = createApp(App)
window.Raphael = Raphael

window._ = _

window.io = SocketIO

app.component('modal', require('./components/bootstrap/modal/modal').default)
app.component('model-header', require('./components/bootstrap/modal/model-header').default)
app.component('model-body', require('./components/bootstrap/modal/model-body').default)
app.component('model-footer', require('./components/bootstrap/modal/model-footer').default)
app.component('downloadExcel', JsonExcel)
registerGlobalComponents(app)

app.use(VueAxios, $axios)
// Vue.config.productionTip = false

app.use(VueViewer)
app.use(Vue3Mq, mqConfig)
app.use(VueFullscreen)
app.use(router)
app.use(store)
app.use(FloatingVue)
app.use(VueTippy)

app.directive('click-outside', vClickOutside)

app.config.globalProperties.$toast = toast
app.use(contextPlugin)

app.mount('#app')

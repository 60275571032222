import StartDateIcon from '@g/src/components/Icon/sort/StartDateIcon.vue'
import DefaultIcon from '@g/src/components/Icon/sort/DefaultIcon.vue'
import UpdatedAtIcon from '@g/src/components/Icon/sort/UpdatedAtIcon.vue'
import CreatedAtIcon from '@g/src/components/Icon/sort/CreatedAtIcon.vue'

export default function registerGlobalComponents (app) {
  app.component('StartDateIcon', StartDateIcon)
  app.component('DefaultIcon', DefaultIcon)
  app.component('UpdatedAtIcon', UpdatedAtIcon)
  app.component('CreatedAtIcon', CreatedAtIcon)
}

import axios from '../axios'

export default {
  addExtinguisherInList ({ commit }, data) {
    axios.post('/storekeeper/extinguishers', data)
      .then((response) => {
        return response
      })
  },
  destroyExtinguisher ({ commit }, id) {
    axios.delete(`/storekeeper/extinguishers/${id}`)
      .then((response) => {
        return response
      })
  },
  async downloadListExtinguishers ({ state, rootGetters, commit }, payload) {
    // TODO: Добавить отмену запроса с помощью AbortController
    try {
      const path = rootGetters['Auth/path'].toLowerCase()
      const response = await axios.get(`/${path}/extinguishers?${payload.args}`)
      commit('setExtinguishers', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      return response
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  clearListExtinguishers ({ commit }) {
    commit('setExtinguishers', [])
  }
}

import axios from '@/store/axios'

export default {
  async downloadListPermissions ({ commit }) {
    try {
      const data = await axios.get('/users/permissions')
      commit('setPermissions', data)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка должностей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  }
}

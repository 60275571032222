import axios from '@/store/axios'

export default {
  async downloadListVideoSources ({ state, commit }, payload) {
    try {
      commit('setVideoSourceLoading', true)

      const response = await axios.get(`/horizon/video-source${payload.args}`)

      if (response) {
        commit('setVideoSources', response.data)
        commit('setTotal', response.total)
        commit('setTotalPage', response.total_pages)
        commit('setPerPage', response.per_page)
      }
    } catch (error) {
      console.error(error)
    } finally {
      commit('setVideoSourceLoading', false)
    }
  },
  async sendImagesData ({ state, commit }, payload) {
    try {
      commit('setSendDataLoading', true)

      await axios.post(`/horizon/video-source/shape/${payload.imageId}`, payload)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setSendDataLoading', false)
    }
  }
}

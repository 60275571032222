import axios from '@/store/axios'

export default {
  async downloadOrdersList ({ commit }) {
    // Загрузка списка заказов
    try {
      const response = await axios.get('/foreman/orders')
      commit('setOrders', response.data)
      commit('setCurrentPage', response.current_page)
      commit('setPerPage', response.per_page)
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка заказов произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadOrder ({ commit, rootGetters }) {
    // Загрузка списка заказов
    try {
      const response = await axios.post('/foreman/orders')
      commit('setOrders', response.data)
      commit('setCurrentPage', response.current_page)
      commit('setPerPage', response.per_page)
    } catch (e) {
      !rootGetters['Auth/userAccesses']['instrument-order'].includes('create')
        ? commit('Notify/setError', 'Для совершения заказа нужно разрешение "Инструмент, заказ: создание".', { root: true })
        : commit('Notify/setError', 'При загрузке списка заказов произошла ошибка. Попробуйте позже.', { root: true })
    }
  }
}

export default {
  user: state => state.user,
  users: state => state.users,
  perPage: state => state.perPage,
  totalRows: state => state.totalRows,
  selectedUser: state => state.selectedUser,
  needOpenModalForNewUser: state => state.needOpenModalForNewUser,
  userAuth: state => state.userAuth,
  userPermissions: state => state.userPermissions
}

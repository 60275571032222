export default {
  videoSources: state => state.videoSources,
  domedVideoSources: state => state.domedVideoSources,
  totalRows: state => state.totalRows,
  domedTotalRows: state => state.totalRows,
  totalPage: state => state.totalPage,
  perPage: state => state.perPage,
  selectedVideoSource: state => state.selectedVideoSource,
  needOpenModalForNewVideoSource: state => state.needOpenModalForNewVideoSource,
  formIsValid: state => state.formIsValid,
  fullScreenVideoWall: state => state.fullScreenVideoWall
}

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  selectedInstruments: [],
  perPage: [],
  currentPage: [],
  instruments: [],
  needReloadList: false
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

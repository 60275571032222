export default {
  module: state => state.module,
  modules: state => state.modules,
  warehouseInModule: state => state.warehouseInModule,
  availableModules: state => state.availableModules,
  availableModule: state => state.availableModule,
  selectedModule: state => state.selectedModule,
  needReloadModules: state => state.needReloadModules,
  needOpenModalForNewModule: state => state.needOpenModalForNewModule,
  perPage: state => state.perPage,
  warehousePerPage: state => state.warehousePerPage,
  totalRows: state => state.totalRows,
  warehouseTotalRows: state => state.warehouseTotalRows,
  registryModule: state => state.registryModule
}

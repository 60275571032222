export default {
  fullScreenGantt: state => state.fullScreenGantt,
  projects: state => state.projects,
  project: state => state.project,
  importProjectId: state => state.importProjectId,
  projectsWidget: state => state.projects
    .filter(project => project.data && project.category === 0)
    .reduce((acc, project) => {
      // TODO: Как бэк переименует поле в лэйбл - удалить дрочь с 6ой по 22 строки включительно
      project.data.planned_personnel.label = project.data.planned_personnel?.title
      delete project.data.planned_personnel?.title
      project.data.personnel_ready_to_repair.label = project.data.personnel_ready_to_repair?.title
      delete project.data.personnel_ready_to_repair?.title
      project.data.required_personnel.label = project.data.required_personnel?.title
      delete project.data.required_personnel?.title
      project.data.planned_materials.label = project.data.planned_materials?.title
      delete project.data.planned_personnel?.title
      project.data.materials_ready_to_repair.label = project.data.materials_ready_to_repair?.title
      delete project.data.personnel_ready_to_repair?.title
      project.data.additional_materials.label = project.data.additional_materials?.title
      delete project.data.additional_materials?.title
      project.data.required_materials.label = project.data.required_materials?.title
      delete project.data.required_personnel?.title
      Object.keys(project.data).forEach(param => {
        if ('label' in project.data[param]) {
          let test = project.data[ param ].label
          test = test.split(' ')
          test.splice(1, 0, `
                    `)
          test = test.join(' ')
          project.data[ param ].label = test
        }
      })
      acc.push(
        {
          id: project.id,
          employeesPrepared: [ project.data.planned_personnel, project.data.personnel_ready_to_repair, project.data.required_personnel ],
          mtrPrepared: [ project.data.planned_materials, project.data.materials_ready_to_repair, project.data.additional_materials, project.data.required_materials ]
        })
      return acc
    }, []),
  openedProjects: state => state?.projects.filter(i => !i.is_active),
  archivedProjects: state => state?.projects.filter(i => i.is_active),
  selectedProject: state => state.selectedProject,
  searchTask: state => state.searchTask,
  projectSearch: state => state.projectSearch,
  patterns: state => state.patterns,
  perPage: state => state.perPage,
  totalRows: state => state.totalRows,
  errors: state => state.errors,
  updatedProject: state => state.updatedProject,
  projectGanttLS: state => state.projectGanttLS
}

import axios from '../axios'
export default {
  async ganttList ({ commit }, data) {
    try {
      const params = new URLSearchParams()
      data.projects.forEach((id, index) => {
        params.append(`projects[${index}]`, id)
      })

      if (data.start_date && data.end_date) {
        params.append('start_date', data.start_date)
        params.append('end_date', data.end_date)
      }

      const response = await axios.get(`/gantt/tasks?${params.toString()}`)
      commit('setGanttList', { list: response.tasks, isOnlyTasks: false })
      return response
    } catch (error) {
      console.error(error)
    }
  },

  async ganttCommit ({ commit }, data) {
    try {
      const params = new URLSearchParams()
      data.projects.forEach((id, index) => {
        params.append(`projects[${index}]`, id)
      })

      if (data.start_date && data.end_date) {
        params.append('start_date', data.start_date)
        params.append('end_date', data.end_date)
      }

      const response = await axios.post(`/gantt/draft/commit?${params.toString()}`)
      if (response) {
        commit('setHasTasksForUpdate', false)
        commit('setGanttList', { list: response.tasks, isOnlyTasks: true })
      }
      return true
    } catch (error) {
      console.error(error)
    }
  },

  async ganttUpdate ({ commit }, payload) {
    try {
      await axios.post('/gantt/draft/update', payload)
      commit('setHasTasksForUpdate', true)
      return true
    } catch (error) {
      console.error(error)
    }
  },

  async ganttAllTaskUpdate ({ commit }, payload) {
    try {
      await axios.post('/gantt/drafts/create', payload)
      commit('setHasTasksForUpdate', true)
      commit('projectHasUpdated', false)
      return true
    } catch (error) {
      console.error(error)
    }
  },
  async ganttFixPlan ({ commit }, payload) {
    try {
      const response = await axios.post('/gantt/set-planned-date', payload)
      commit('setGanttList', { list: response.tasks, isOnlyTasks: true })
      return response
    } catch (error) {
      console.error(error)
    }
  },

  async ganttRollback ({ commit }, data) {
    try {
      const params = new URLSearchParams()
      data.projects.forEach((id, index) => {
        params.append(`projects[${index}]`, id)
      })

      if (data.start_date && data.end_date) {
        params.append('start_date', data.start_date)
        params.append('end_date', data.end_date)
      }
      return await axios.post(`/gantt/draft/rollback?${params.toString()}`)
    } catch (error) {
      console.error(error)
    }
  },

  async createLink ({ commit }, payload) {
    try {
      const res = await axios.post('/gantt/links', payload)
      commit('setNewLink', res)
      commit('setHasTasksForUpdate', true)
      return res
    } catch (error) {
      console.error(error)
    }
  },

  async deletedLink ({ state, commit }, id) {
    try {
      await axios.delete(`gantt/links/${id}?is_draft=${1}`)
      commit('setDeleteLink', id)
      commit('setHasTasksForUpdate', true)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async ganttAddTask ({ commit, state }, data) {
    try {
      const response = await axios.post('tasks', data)

      commit('setGanttNewTask', response)
      return response
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      return false
    }
  },

  async ganttDeletedTask ({ commit, state }, taskId) {
    try {
      await axios.delete(`tasks/${ taskId }?is_draft=${1}`)
      commit('setHasTasksForUpdate', true)
      // commit('TaskList/hasDelete', true, { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async ganttCopyTasks ({ commit, state }, payload) {
    const { taskId, type } = payload
    const typeValue = type ? 1 : 0
    try {
      const res = await axios.post(`gantt/${taskId}/clone?with_subtask=${typeValue}&is_draft=${1}`)
      if (res) {
        commit('setCopyTasks', res.tasks)
      }
      return true
    } catch (e) {
      commit('Notify/setError', 'При копировании произошла ошибка. Попробуйте позже', { root: true })
    }
  },
  async ganttPastTask ({ commit, state }, payload) {
    const { copyTaskId, type, isProject, pastTaskId, projectId } = payload

    try {
      const res = await axios.post(
        `gantt/${copyTaskId}/clone`,
        {
          with_subtask: !!type,
          project_id: projectId,
          state_id: isProject ? undefined : pastTaskId
        }
      )

      if (res) {
        commit('setCopyTasks', res.tasks)
      }
      return res
    } catch (e) {
      commit('Notify/setError', 'При копировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async planHistory ({ commit, state }, payload) {
    try {
      await axios.post('repairs/metrics/set_plan_history', payload)
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении фиксированного плана произошла ошибка. Попробуйте позднее', { root: true })
    }
  }
}

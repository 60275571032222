export default {
  setPositions (state, data) {
    state.positions = data
  },
  setPerPage (state, data) {
    state.perPage = data
  },
  setTotalRows (state, data) {
    state.totalRows = data
  },
  setSelectedPosition (state, value) {
    state.selectedPosition = value
  },
  setNeedOpenModalForPosition (state, value) {
    state.needOpenModalForPosition = value
  }
}

export default {
  setRoles: (state, data) => state.roles = data,
  setPerPage (state, data) {
    state.perPage = data
  },
  setTotalRows (state, data) {
    state.totalRows = data
  },
  setSelectedRole: (state, value) => state.selectedRole = value,
  setNeedOpenModalForRole: (state, value) => state.needOpenModalForRole = value
}

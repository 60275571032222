export default {
  setUser (state, value) {
    state.user = value
  },
  setUsers (state, value) {
    state.users = value
  },
  setPerPage (state, value) {
    state.perPage = value
  },
  setTotalRows (state, value) {
    state.totalRows = value
  },
  setSelectedUser (state, value) {
    state.selectedUser = value
  },
  setNeedOpenModalForNewUser (state, value) {
    state.needOpenModalForNewUser = value
  },
  setUserAuth (state, data) {
    state.userAuth = data
  },
  setUserPermissions (state, roles) {
    if (!roles) return
    state.userPermissions = []
    const uniquePermissions = new Set()

    roles.forEach(role => {
      role.permissions.forEach(permission => {
        const { name } = permission

        const validSuffixes = ['list', 'show', 'create', 'update', 'delete']

        const baseName = validSuffixes.reduce((acc, suffix) => {
          if (name.endsWith(`-${suffix}`)) {
            return name.slice(0, -suffix.length - 1)
          }
          return acc
        }, name)
        if (name.includes('module-list')) uniquePermissions.add('/assign-module')
        if (name.includes('instrument-history-list')) uniquePermissions.add('/status-history')
        uniquePermissions.add(`/${baseName}`)
      })
    })
    state.userPermissions = [...Array.from(uniquePermissions), '/profile', '/sign-out']
    if (state.userPermissions.includes('/task') && state.userPermissions.includes('/project') && state.userPermissions.includes('/demo')) state.userPermissions.push('/projects-and-tasks')
    if (state.userPermissions.includes('/work-zone') && state.userPermissions.includes('/demo')) state.userPermissions.push('/video-sync')
    if (state.userPermissions.includes('/task')) state.userPermissions.push('/my-tasks-today')
    if (state.userPermissions.includes('/warehouse')) state.userPermissions.push('/warehouses-profile')
    if (state.userPermissions.includes('/warehouse-write-off')) state.userPermissions.push('/write-off')
    if (state.userPermissions.includes('/work-zone')) state.userPermissions.push('/video-wall')
  }
}

import { createRouter, createWebHistory } from 'vue-router'
import main from '../router/sideBar/main'
import user from './sideBar/user'
import pages from './pages/main'
import store from '../store'

const routes = [
  ...main,
  ...user,
  ...pages
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const toAuthPage = to.matched.some(route => route.meta.auth)
  const fromAuthPage = from.matched.some(route => route.meta.auth)
  const localStorageUser = JSON.parse(localStorage.getItem('user'))

  const currentUser = store.getters['Auth/user']

  const defaultRoutes = [ '/sign-in', '/auth/sign-out', '/sign-out', '/', '/profile', '/repairs/', '/dashboard/1', '/dashboard/2', '/dashboard/3', '/search/instruments', '/stream-video/test', '/horizon-notifications' ]
  if (!defaultRoutes.includes(to.path)
    && !store.getters['Auth/userAccessRoutes'].some(route => route.includes(to.path.includes('-')
      ? to.path.slice(0, -1)
      : to.path.slice(0, -1)))
  ) next(from.path || '/sign-in')

  // Если была получена 401 ошибка при любом запросе
  if (to.query.status === '401') {
    if (fromAuthPage) {
      store.commit('Notify/setError', 'Сессия была завершена. Для продолжения работы необходимо заново войти в систему.')
      return next(to.path)
    } else {
      return next(false)
    }
  }

  if (!currentUser && localStorageUser) {
    store.commit('Auth/setUser', localStorageUser)
  }

  if (toAuthPage && !currentUser && !localStorageUser) {
    return next('/sign-in')
  }
  return next()
})
export default router

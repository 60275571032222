import axios from '../axios'
import { deleteAllCookies } from '@/Utils/helpers'

export default {
  async signOut ({ commit }) {
    commit('clearUser')
    commit('TaskList/resetFilters', undefined, { root: true })
    commit('TaskList/resetSearch', undefined, { root: true })
    commit('StatusHistory/resetFilters', undefined, { root: true })
    commit('StatusHistory/resetSearch', undefined, { root: true })
    window.localStorage.routes = null
    try {
      await axios.post('/logout')
    } catch (e) {
      if (e.handled) return
      commit('Notify/setError', 'При выходе из системы произошла ошибка. Попробуйте позже.', { root: true })
    }

    deleteAllCookies()
    delete axios.defaults.headers['Authorization']
  },
  async signIn ({ state, getters, dispatch, commit }, form) {
    await axios.get(process.env.VUE_APP_BACKEND_BASE + '/sanctum/csrf-cookie')
    try {
      const responseLogin = await axios.post('/login', {
        email: form.email,
        password: form.password
      })
      if (responseLogin) {
        axios.defaults.headers['Authorization'] = 'Bearer ' + responseLogin.token
        dispatch('Roles/downloadListRoles', { args: '?per_page=10000' }, { root: true })
        const responseAuth = await dispatch('UserList/userAuth', null, { root: true })
        if (responseAuth.message === 'Смените пароль') {
          commit('Notify/setDanger', responseAuth.message, { root: true })
          commit('setFirstLogin', responseAuth.user.id)
        } else {
          commit('setFirstLogin', undefined)
          commit('setUser', responseLogin.user)
          commit('User/setSelectProfile', responseLogin.user, { root: true })
          commit('setUserRoles', responseLogin.user.roles)
          commit('Education/setEducations', responseLogin.user.educations, { root: true })

          localStorage.setItem('token', responseLogin.token)
          localStorage.setItem('accesses', JSON.stringify(getters.userAccesses))
          if (!form.type) commit('Notify/setSuccess', 'Вход в систему', { root: true })
        }
      }
      return true
    } catch (e) {
      // Обрабатываем структуру, что приходит объект errors, который содержит массивы ошибок для каждого ключа
      if (e.response?.data?.errors) {
        for (const key in e.response?.data?.errors) {
          if (Array.isArray(e.response?.data?.errors[key])) {
            e.response?.data?.errors[key].forEach(errorMessage => {
              commit('Notify/setError', errorMessage, { root: true })
            })
          } else {
            commit('Notify/setError', e.response?.data?.errors[key], { root: true })
          }
        }
      } else {
        commit('Notify/setError', e.response?.data?.message || 'При входе в систему произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async signInFace ({ commit }, photo) {
    await axios.get(process.env.VUE_APP_BACKEND_BASE + '/sanctum/csrf-cookie')
    try {
      const responseLogin = await axios.post('/auth-face', { photo })
      commit('setUser', responseLogin.user)
      localStorage.setItem('token', responseLogin.token)
      axios.defaults.headers['Authorization'] = 'Bearer ' + responseLogin.token
    } catch (e) {
      if (e.handled) return
      commit('setErrors', e)
      commit('Notify/setError', 'При входе в систему произошла ошибка. Попробуйте позже.', { root: true })
    }
  }
}

export default {
  setInfo: (state, value) => {
    state.info = value
  },
  setSuccess: (state, value) => {
    state.success = value
  },
  setError: (state, value) => {
    state.error = value
  },
  setDanger: (state, value) => {
    state.danger = value
  }
}

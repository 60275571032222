import axios from '../axios'

export default {
  async setEducation ({ commit }, learnId) {
    try {
      await axios.post('/users/educations/attach', { educations: [ learnId ] })
      commit('Notify/setSuccess', 'Информация о пройденном обучении передана', { root: true })
    } catch (e) {
      console.log(e)
      commit('Notify/setError', 'Информация о пройденном обучении не передана', { root: true })
    }
  }
}

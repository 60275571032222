import axios from '../axios'
import store from '@/store'

export default {
  async uploadFiles ({ commit }, files) {
    const data = new FormData()
    files.map((file, index) => {
      data.set(`files[${index}]`, file)
    })
    try {
      const response = await axios.post('upload-file', data)
      if (!Array.isArray(response)) return
      return response.map(file => {
        file.isUploaded = true
        return file
      })
    } catch (e) {
      store.commit('Notify/setError', 'При загрузке файла произошла ошибка. Попробуйте позже.')
    }
  },
  async uploadFileRecognize ({ commit }, payload) {
    try {
      const response = await axios.post('face/recognize', payload)
      commit('setUserForSearch', response.user)
      return response.user
    } catch (e) {
      console.log('error', e)
      return e
      // store.commit('Notify/setError', 'При загрузке файла произошла ошибка. Попробуйте позже.')
    }
  }
}

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  instruments: [],
  selectedInstruments: [],
  perPage: undefined,
  totalRows: undefined,
  addedInstrument: undefined,
  needReloadList: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default {
  user: state => state.user,
  path: state => state.path,
  errors: state => state.errors,
  isFirstLogin: state => state.isFirstLogin,
  userAccessesProto: state => [ ...new Set(state.userRoles.reduce((acc, role) => {
      acc.push(...role.permissions.map(perm => perm.name))
      return acc
    }, [])
  )],
  userAccesses: (state, getters) => getters.userAccessesProto.reduce((acc, access) => {
    const route = access.split('-').length > 2
      ? access.split('-').slice(0, access.split('-').length - 1).join('-')
      : access.split('-')[ 0 ]
    const acs = access.split('-').length > 2
      ? access.split('-')[ access.split('-').length - 1 ]
      : access.split('-')[ 1 ]
    if (acc[ route ]) acc[ route ] = [ ...acc[ route ], acs ]
    else acc[ route ] = [ acs ]
    return acc
  }, { user: [ 'show' ] }),
  userAccessRoutes: (state, getters) => {
    const proto = [ ...new Set(getters.userAccessesProto.map(access => access.split('-').length > 2
      ? '/' + access.split('-').slice(0, access.split('-').length - 1).join('-')
      : '/' + access.split('-')[ 0 ]
    )) ].sort()

    const exceptions = [ '/comment', '/config', '/file' ]
    let result = proto.filter(path => !exceptions.includes(path))
    if (result.includes('/warehouse')) result.push('/warehouses-profile')
    if (result.includes('/work-zone')) result.push('/video-wall')
    if (result.includes('/task')) result.push('/my-tasks-today')
    if (result.includes('/module-checkpoint')) result.push('/checkpoints')
    if (getters.userAccesses.user && !getters.userAccesses.user.includes('create')) result = result.filter(url => url !== '/user')
    if (getters.userAccesses.module && getters.userAccesses.module.includes('list')) result.push('/assign-module')
    if (getters.userAccesses['warehouse-write-off'] && getters.userAccesses['warehouse-write-off'].includes('list')) {
      result = result.filter(route => route !== '/warehouse-write-off')
      result.push('/write-off')
    }
    if (getters.userAccesses.warehouse || getters.userAccesses.instrument) result.push('/instruments')
    if (getters.userAccesses['instrument-history'] && getters.userAccesses['instrument-history'].includes('list')) result.push(...[ '/instrument-history', '/status-history' ])
    if (getters.userAccesses['instrument-document'] && getters.userAccesses['instrument-document'].includes('list')) result.push('/decommissioned')
    if (getters.userAccesses['project'] && getters.userAccesses['task'].includes('list')) result.push('/projects-and-tasks')
    if (getters.userAccesses['work-zone']) result.push('/video-sync')

    return result
  }
}

export default {
  columns: state => state.columns,
  shownColumns: state => state.columns.filter(column => column.fixed || column.show),
  instruments: state => state.instruments,
  inspectorInstrumentInModal: state => state.inspectorInstrumentInModal,
  inspectorInstrument: state => {
    if (!state.instrumentNameFilter) return state.inspectorInstrument
    const nameFilter = state.instrumentNameFilter.toLowerCase()
    return state.inspectorInstrument.filter(item => item.name.toLowerCase().includes(nameFilter))
  },
  filteredInstruments: state => {
    if (!state.instrumentNameFilter) return state.instruments
    const nameFilter = state.instrumentNameFilter.toLowerCase()
    return state.instruments.filter(item => item.name.toLowerCase().includes(nameFilter))
  },
  totalRows: state => state.totalRows,
  perPage: state => state.perPage,
  selectedInstrument: state => state.selectedInstrument,
  needOpenModalForNewInstrument: state => state.needOpenModalForNewInstrument,
  viewFullScreen: state => state.viewFullScreen,
  warehouseShow: state => state.warehouseShow,
  isUpdateRegistryStatus: state => state.isUpdateRegistryStatus
}

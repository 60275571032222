import { updateShownColumnsKeys } from '@/Utils/helpers'

export default {
  setColumns (state, shown) {
    for (const column of state.columns) {
      if (column.fixed) continue
      column.show = shown.includes(column.key)
    }
    updateShownColumnsKeys('instrument', shown)
  },
  setColumnsForInspector (state, shown) {
    for (const column of state.columnsForInspector) {
      if (column.fixed) continue
      column.show = shown.includes(column.key)
    }
    updateShownColumnsKeys('instrumentForInspector', shown)
  },
  setColumnsForForeman (state, shown) {
    for (const column of state.columnsForForeman) {
      if (column.fixed) continue
      column.show = shown.includes(column.key)
    }
    updateShownColumnsKeys('instrumentForForeman', shown)
  },
  setInstruments (state, data) {
    state.instruments = data
  },
  setInstrument (state, data) {
    state.instruments.unshift(data)
  },
  setInstrumentShow (state, data) {
    state.instrumentShow = data
  },
  setOneInstrument (state, data) {
    state.instrument = data
  },
  setScannedRfidMark (state, data) {
    state.scannedRfidMark = data
  },
  setInstrumentsWithoutRfid (state, data) {
    state.instrumentsWithoutRfid = data
  },
  setInstrumentWithoutRfid (state, data) {
    state.instrumentWithoutRfid = data
  },
  setUsersWithPasses (state, data) {
    state.usersWithPasses = data
  },
  setNeedReloadInstruments (state, data) {
    state.needReloadInstruments = data
  },
  setPerPage (state, data) {
    state.perPage = data
  },
  setTotalRows (state, data) {
    state.totalRows = data
  },
  setScannedInstruments (state, data) {
    state.scannedInstruments = data
  },
  addScannedInstrument (state, item) {
    const foundInstrument = state.scannedInstruments.find(instrument => instrument.id === item.id)
    if (foundInstrument) return
    state.scannedInstruments.push(item)
  },
  deleteScannedInstrument (state, id) {
    state.scannedInstruments = state.scannedInstruments.filter(item => item.id !== id)
  },
  setSelectedInstrumentType (state, data) {
    state.selectedInstrumentType = data
  },
  setSelectedInstrument (state, value) {
    state.selectedInstrument = value
  },
  setSelectedInstruments (state, value) {
    if (!value) state.selectedInstruments = []
    else {
      const instruments = state.selectedInstruments
      instruments.includes(value)
        ? instruments.splice(instruments.indexOf(value), 1)
        : instruments.push(value.id)
      state.selectedInstruments = instruments
    }
  },
  setNeedOpenModalForNewInstrument (state, value) {
    state.needOpenModalForNewInstrument = value
  },
  setAddedInstrument (state, value) {
    state.addedInstrument = value
  },

  clearFieldAddedInstrument (state, value) {
    state.addedInstrument = Object.fromEntries(
      Object.entries(state.addedInstrument).filter(item => item[0] !== value)
    )
  },
  updateAddedInstrument (state, payload) {
    state.addedInstrument = {
      ...state.addedInstrument,
      ...payload
    }
  },
  setSearch: (state, value) => {
    state.search = value
  },
  setUserJunk: (state, value) => {
    state.userJunk = value
  }
}

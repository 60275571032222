export default {
  employees: state => state.employees,
  selectedEmployee: state => state.selectedEmployee,
  perPage: state => state.perPage,
  totalRows: state => state.totalRows,
  employeesWithoutRfid: state => state.employeesWithoutRfid,
  employeeWithoutRfid: state => state.employeeWithoutRfid,
  scannedNewRfidPass: state => state.scannedNewRfidPass,
  scannedEmployeePass: state => state.scannedEmployeePass,
  availableResponsibleList: state => state.availableResponsibleList,
  responsiblePerPage: state => state.responsiblePerPage,
  responsibleTotalRows: state => state.responsibleTotalRows
}

import axios from '../axios'

export default {
  async uploadConfirmedWriteOff ({ state, commit }, payload) {
    const data = new FormData()
    payload.map((value, index) => {
      data.set(`instruments[${ index }]`, value)
    })
    try {
      const res = await axios.post('foreman/instruments/write-off', data)
      commit('setNeedReloadList', true)
      commit('Notify/setSuccess', 'Инструмент списан', { root: true })
      return res
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При списании произошла ошибка. Попробуйте позже.', { root: true })
        return false
      }
    }
  },
  async downloadWriteOffList ({ commit }) {
    try {
      const response = await axios.get('foreman/instrument/documents')
      commit('setInstruments', response.data)
      commit('setCurrentPage', response.meta.current_page)
      commit('setPerPage', response.meta.per_page)
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка заказов произошла ошибка. Попробуйте позже.', { root: true })
    }
  }
}

import axios from '../axios'

export default {
  // Получение списка задач
  async downloadTaskList ({ rootState, commit }, params) {
    try {
      commit('setTaskLoading', true)

      const response = await axios.get('/tasks', {
        params: {
          'filter[state]': 1,
          'sort[column]': params.sortValue ?? 'updated_at',
          'sort[type]': params.sortType ?? 'desc',
          'filter[statuses][0]': 'open',
          'filter[statuses][1]': 'progress',
          'filter[statuses][2]': 'done',
          'filter[statuses][3]': 'verify',
          'filter[projects][0]': params.projectId,
          page: params.currentPage
        }
      })

      commit('setTaskList', response.data)
      commit('setTaskPageData', response)
    } catch (error) {
      console.error('error', error)
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setTaskLoading', false)
    }
  },
  async deletedTask ({ commit, state }, task) {
    // удаление задач из списка
    try {
      commit('setTaskDeleteLoading', true)
      await axios.delete(`tasks/${ task.id }`)
      commit('deleteTask', task)
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setTaskDeleteLoading', false)
    }
  }
}

export default {
  setConfigs (state, value) {
    localStorage.setItem('configs', JSON.stringify(value))
    state.configs = value
  },
  setConfigWorkZones (state, value) {
    state.configs.work_zones.push(value)
  },
  setRemoveConfigWorkZones (state, value) {
    state.configs.work_zones = state.configs.work_zones.filter(workZone => workZone.id !== value)
  },
  setSortedResponsibles (state, value) {
    state.sortedResponsiples = value
  },
  setInstrumentNameFilter (state, value) {
    state.instrumentNameFilter = value
  },
  setResponsibleFilter (state, value) {
    state.responsibleFilter = value
  },
  setWorkZoneFilter (state, value) {
    state.workZoneFilter = value
  },
  setFont (state, value) {
    document.body.classList.remove(`font-${state.font}`)
    document.body.classList.add(`font-${value}`)
    localStorage.setItem('font', value)
    state.font = value
  }
}

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  images: [],
  videoSources: [],
  videoSourcesPage: 1,
  videoSourcesPerPage: 0,
  videoSourcesTotal: 0,
  videoSourcesTotalPages: 0,
  isSendDataLoading: false,
  selectedImage: {
    src: null,
    id: null,
    polygonData: {
      polygons: [],
      selectedPolygon: null,
      polygonCount: 0
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

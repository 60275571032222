import axios from '../axios'

export default {
  async downloadListInstruments ({ commit }, payload) {
    try {
      const { data } = await axios.get(`/instruments?${payload.args}`)
      commit('setInstruments', data)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadSearchListInstruments ({ commit }, payload) {
    try {
      const { data } = await axios.get(`/search/instruments?${payload.args}`)
      commit('setInstruments', data)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  }
}

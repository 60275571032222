import axios from '../axios'

export default {
  async createCheckpoint ({ commit }, payload) {
    try {
      await axios.post('modules/checkpoints', payload)
      return true
    } catch (e) {
      commit('Notify/setError', 'При создании рамки произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async downloadListCheckpoints ({ rootState, state, commit }, payload) {
    const { args, pagination } = payload
    try {
      const response = await axios.get(`modules/checkpoints${args}`)
      if (pagination) {
        state.checkpoints.push(...response.data)
        commit('setCheckpoints', state.checkpoints)
      } else {
        commit('setCheckpoints', response.data)
      }
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      return true
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка контрольных точек произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async removeCheckpoint ({ state, commit }) {
    try {
      const id = state.selectedCheckpoint.id
      await axios.delete(`modules/checkpoints/${id}`)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении контрольной точки возникла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async editCheckpoint ({ state, rootGetters, commit }, payload) {
    try {
      const { id, model } = payload
      const response = await axios.put(`modules/checkpoints/${id}`, model)
      const name = response.title ? `${response.title}` : ''
      commit('Notify/setSuccess', `Контрольная точка ${name} успешно изменена`, { root: true })
      commit('setSelectedCheckpoint', response)
      return response
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async moduleShow ({ rootState, state, commit }, id) {
    try {
      const response = await axios.get(`admin/modules/${id}`)
      commit('setModule', response)
      return true
    } catch (e) {
      if (!e.cancelled) {
        // commit('Notify/setError', 'При загрузке списка модулей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadListWarehouseInModule ({ rootState, state, commit }, payload) {
    const { name, id, page } = payload
    const search = name ? `search=${name}&` : ''
    try {
      const response = await axios.get(`admin/warehouses?${search}filter[modules][0]=${id}&page=${page}`)
      commit('setWarehouseInModule', response.data)
      commit('setWarehousePerPage', response.per_page)
      commit('setWarehouseTotalRows', response.total)
      return true
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка модулей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadListAvailableModules ({ state, commit }, payload) {
    // TODO: Добавить отмену запроса с помощью AbortController

    try {
      const args = payload && payload.args ? payload.args : ''
      const response = await axios.get(`storekeeper/modules?${args}`)
      commit('setAvailableModules', response.data)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка модулей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async uploadAssignedModule ({ state, commit, rootGetters }) {
    try {
      const userRfid = rootGetters['Employee/scannedEmployeePass'].RFID
      const moduleRfid = state.availableModule.RFID
      const data = new FormData()
      data.set('user', userRfid)
      data.set('module', moduleRfid)
      const response = await axios.post('storekeeper/modules', data)
      return {
        moduleName: response.title,
        userName: `${response.user.last_name} ${response.user.first_name}`
      }
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  clearListModules ({ commit }) {
    commit('setModules', [])
    commit('setTotalRows', undefined)
    commit('setPerPage', undefined)
  },
  updateModule ({ commit, state }, data) {
    const foundModule = state.modules.find(item => item.id === data.id)
    if (!foundModule) return
    commit('updateModule', data)
    commit('Notify/setInfo', `Обновлена информация о модуле "${data.title}"`, { root: true })
  },
  async searchModule ({ commit }, id) {
    try {
      await axios.get(`/module/${id}/detect`)
      commit('Notify/setSuccess', 'Модуль найден', { root: true })
    } catch (e) {
      commit('Notify/setError', 'При поиске произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async registryModule ({ commit }, payload) {
    try {
      const response = await axios.get(`admin/modules/${payload.id}/registry`)
      commit('setRegistryModule', response.warehouses)
    } catch (e) {
      commit('Notify/setError', 'При загрузке реестра произошла ошибка. Попробуйте позже.', { root: true })
    }
  }
}

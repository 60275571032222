import { toSentenceFormat } from '@/Utils/helpers'

const COMMON_OPTIONS = {
  autoHideDelay: 10000
}

const OPTIONS = {
  default: {
    title: ''
  },
  primary: {
    title: '',
    variant: 'primary'
  },
  secondary: {
    title: '',
    variant: 'secondary'
  },
  danger: {
    title: '',
    variant: 'danger'
  },
  error: {
    title: '',
    variant: 'warning'
  },
  success: {
    title: '',
    variant: 'success'
  },
  info: {
    title: '',
    variant: 'info'
  }
}

export default function (value, type) {
  if (!value || !value.length) return
  const showToast = msg => {
    this.$bvToast.toast(msg, {
      ...COMMON_OPTIONS,
      ...OPTIONS[type || 'default']
    })
  }
  if (_.isString(value)) {
    showToast(value)
  } else {
    if (_.isArray(value)) {
      for (const msg of value) {
        showToast(msg)
      }
    }
  }
  const mutationName = `Notify/set${toSentenceFormat(type)}`
  this.$store.commit(mutationName, null)
}

export function compositionToast (value, type, bvToast, commit) {
  if (!value || !value.length) return
  const showToast = msg => {
    bvToast.toast(msg, {
      ...COMMON_OPTIONS,
      ...OPTIONS[type || 'default']
    })
  }
  if (_.isString(value)) {
    showToast(value)
  } else {
    if (_.isArray(value)) {
      for (const msg of value) {
        showToast(msg)
      }
    }
  }
  const mutationName = `Notify/set${toSentenceFormat(type)}`
  commit(mutationName, null)
}

import _ from 'lodash'
import { initHistoryFilters } from '@/store/StatusHistory/index'

export default {
  setFilters (state, filterObject) {
    localStorage.setItem('historyFilters', JSON.stringify(filterObject))
    state.filters = _.cloneDeep(filterObject)
  },
  setUsers (state, filterObject) {
    localStorage.setItem('historyUser', JSON.stringify(filterObject))
    state.user = _.cloneDeep(filterObject)
  },
  resetFilters (state) {
    localStorage.removeItem('historyFilters')
    state.filters = initHistoryFilters
    state.user = undefined
  },
  setSearch (state, value) {
    localStorage.setItem('historySearch', value)
    state.search = value
  },
  resetSearch (state) {
    localStorage.removeItem('historySearch')
    state.search = ''
  },
  setStatusHistory (state, value) {
    state.statusHistory = value
  },
  setEventHistory (state, value) {
    state.statusHistory.unshift(value.history)
  },
  setSelectedProduct (state, value) {
    state.selectedProduct = value
  }
}
